export default {
    data() {
      return {
          rem100: null
      }
    },
    methods: {
        cloneOverJson(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        inRem(px) {
            if (!this.rem100) {
                this.rem100 = this.$refs.remEx.offsetHeight
            }
            return this.rem100 * px / 100
        },
        getuserID() {
            let userID = window.localStorage.getItem('userID')
            if (!userID) {
                userID = Math.random().toString(16).slice(2)
                window.localStorage.setItem('userID', userID)

                return userID
            }

            return userID
        }
    }
}